import { useAuth } from "app/context/AuthContext";
import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";

const AuthWrapper = () => {
  const auth = useAuth();
  const location = useLocation();
  console.log("Auth", auth);

  return (
    <>
    {  auth.user  ? (
      <Outlet />
      ) : (
      <Navigate to="/" state={{ from: location }} replace />

      )}
    </>
  );
};

export default AuthWrapper;
