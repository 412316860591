import React, {useEffect, useState} from "react";

import { Typography } from "@mui/material";

import Datagrid from "app/components/Datagrid";
import { useTranslation } from "react-i18next";

const Rentals = () => {
  const [refetch, setRefetch] = useState(1);

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 150,
      renderCell: (params) => (
        <Typography variant="body">{params.row.car.name}</Typography>
      ),
    },
    {
      field: "Regno",
      headerName: "Reg No.",
      width: 150,
      renderCell: (params) => (
        <Typography variant="body">{params.row.car.RegNo}</Typography>
      ),
    },
    {
      field: "sdd",
      headerName: "Rent per day",
      width: 150,
      renderCell: (params) => (
        <Typography variant="body">{params.row.rentPerDay}</Typography>
      ),
    },
    { field: "status", headerName: "Status", width: 150 },
   
    {
      headerName: "Available From",
      field: "_id",
      sortable: false,
      width: 175,
      renderCell: (params) => (
        <Typography variant="body">
          {new Date(params.row.startTime).toLocaleString()}
        </Typography>
      ),
    },
    {
      headerName: "Available Till",
      field: "_id",
      sortable: false,
      width: 175,
      renderCell: (params) => (
        <Typography variant="body">
          {new Date(params.row.endTime).toLocaleString()}
        </Typography>
      ),
    },

  ];

  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h1" mb={3}>
        {t("pages.title.rentals")}
      </Typography>

      {/* Modal  */}

      <Datagrid url={'/rentals'} columns={columns} refetch={refetch}/>
    </>
  );
};

export default Rentals;
