import React, { createContext, useContext, useState, useEffect } from "react";
import {
  checkAuthStatus,
  loginUser,
  logoutUser,
  signupUser,
} from "../apis/api-communicator";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  let [user, setUser] = useState(null);
  let [isLoggedIn, setIsLoggedIn] = useState(false);
  let [role, setRole] = useState(null);
  useEffect(() => {
    // fetch if user's cookies are valid then skip login
    (async function checkStatus() {
      try {
        let data = await checkAuthStatus();
        console.log("data ", data);
        if (data) {
          setUser({ email: data.email, name: data.name, id:data.id });
          setIsLoggedIn(true);
          setRole(data.role);
          console.log("auth successful", user, role);
        }
      } catch (error) {
        console.log("auth failed");
      }
    })();
  }, []);
  // useEffect(() => {
  //   console.log(user, isLoggedIn, role);
  // });

  const login = async (email, password) => {
    const data = await loginUser(email, password);
    console.log('userData', data);
    if (data) {
      setUser({ email: data.email, name: data.name,id:data.id });
      setRole(data.role);
      localStorage.setItem('role', data.role);

      setIsLoggedIn(true);
      return data

    }
  };
  const signup = async (name, email, password) => {
    const data = await signupUser(name, email, password);
    if (data) {
      setUser({ email: data.email, name: data.name });
      setIsLoggedIn(true);
    }
  };
  const logout = async () => {
    await logoutUser();
    setIsLoggedIn(false);
    setRole("");
    localStorage.removeItem('role')
    setUser(null);
    // window.location.reload();
  };

  const value = {
    user,
    isLoggedIn,
    role,
    login,
    logout,
    signup,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
