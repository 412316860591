import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AppLayout from "./AppLayout";
import AuthWrapper from "./utils/AuthWrapper";
import Page from "@jumbo/shared/Page";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import Drivers from "./pages/Drivers";
import Error404 from "./pages/Error404";
import SubAdmins from "./pages/SubAdmins";
import Riders from "./pages/Riders";
import Rentals from "./pages/Rentals";
import IntroScreens from "./pages/IntroScreens";
import Payments from "./pages/Payments";
import UserProfile from "./pages/UserProfile";
import LegalTerms from "./pages/LegalTerms";
import HowItWorks from "./pages/HowItWorks";
import BusinessSignup from "./pages/BusinessSignup";
import Corporates from "./pages/Corporates";
import CorporateHome from "./pages/CorporateHome";
import VerifyEmail from "./pages/VerifyEmail";
import CorporateUsers from "./pages/CorporateUsers";

const AppRoutes = () => {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path={'business-signup'} element={<BusinessSignup />} />
        <Route path="/verifyEmail/:token" element={<VerifyEmail />} />

        {/* pages with layout and authentication*/}
      {/* <Route element={<AppLayout />}> */}
      {/* <Route element={<AuthWrapper />}> */}
      {/* <Route path="*" element={<AppLayout />}> */}
      <Route
        path="admin"
        element={
          <AppLayout>
            <AuthWrapper />
          </AppLayout>
        }
      >
        <Route index element={<Dashboard />} />
        <Route path="drivers" element={<Drivers />} />
        <Route path="corporates" element={<Corporates />} />
        <Route path="subAdmins" element={<SubAdmins />} />
        <Route path="riders" element={<Riders />} />
        <Route path="intro-screens" element={<IntroScreens />} />
        <Route path="rentals" element={<Rentals />} />
        <Route path="payments" element={<Payments />} />
        <Route path="profile" element={<UserProfile />} />
        <Route path="legal" element={<LegalTerms />} />
        <Route path="howItWorks" element={<HowItWorks />} />

      </Route>
        <Route
            path="corporate"
            element={
                <AppLayout>
                    <AuthWrapper />
                </AppLayout>
            }
        >
        <Route index element={<CorporateHome />} />
        <Route path="users" element={<CorporateUsers />} />
        </Route>

        {/*<Route path="*" element={<Error404 />} />*/}
        <Route path="*" element={<Login />} />
    </Routes>
  );
};

export default AppRoutes;
