import React, {useState} from "react";

import {Typography} from "@mui/material";
import Datagrid from "app/components/Datagrid";
import {useTranslation} from "react-i18next";

const Payments = () => {
    const columns = [
        {field: "paymentFor", headerName: "Service", width: 150},
        {
            field: "payeeId",
            headerName: "Payer",
            width: 150,
            renderCell: (params) => (
                <Typography variant="body">{params.row.payerId.name}</Typography>
            ),
        },
        {
            field: "payerId",
            headerName: "Paye",
            width: 150,
            renderCell: (params) => (
                <Typography variant="body">{params.row.payeeId?.name}</Typography>
            ),
        },
        {field: "status", headerName: "Status", width: 150},
        {
            field: "stripePaymentIntentID",
            headerName: "Stripe Intent Id",
            width: 300,
        },
        {
            headerName: "Time",
            field: "_id",
            sortable: false,
            width: 175,
            renderCell: (params) => (
                <Typography variant="body">
                    {new Date(params.row.createdAt).toLocaleString()}
                </Typography>
            ),
        },
    ];
    const [refetch, setRefetch] = useState(1);


    const {t} = useTranslation();
    return (
        <>
            <Typography variant="h1" mb={3}>
                {t("pages.title.payments")}
            </Typography>

            {/* Modal  */}

            <Datagrid url={"/payments"} columns={columns} refetch={refetch}/>
        </>
    );
};

export default Payments;
