import React from 'react';
import {CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Div from "@jumbo/shared/Div";
import {capitalizeFLetter} from "@jumbo/utils";
import {DashboardCardHeader} from "../../DashBoard/CardHeader";
// sample data
// let data = [
//     {
//         "name": "Page A",
//         "month": "Jan",
//         "signups": 1200,
//     },
//     {
//         "name": "Page B",
//         "month": "Feb",
//         "signups": 1600,
//     },
//     {
//         "name": "Page C",
//         "month": "Mar",
//         "signups": 3000,
//     },
//     {
//         "name": "Page D",
//         "month": "Apr",
//         "signups": 3600,
//     },
//     {
//         "name": "Page E",
//         "month": "May",
//         "signups": 4800,
//     },
//     {
//         "name": "Page F",
//         "month": "Jun",
//         "signups": 4200,
//     },
//     {
//         "name": "Page G",
//         "month": "Jul",
//         "signups": 4600,
//     },
//     {
//         "name": "Page C",
//         "month": "Aug",
//         "signups": 3800,
//     },
//     {
//         "name": "Page D",
//         "month": "Sep",
//         "signups": 5200,
//     },
//     {
//         "name": "Page E",
//         "month": "Oct",
//         "signups": 5800,
//     },
//     {
//         "name": "Page F",
//         "month": "Nov",
//         "signups": 6800,
//     },
//     {
//         "name": "Page G",
//         "month": "Dec",
//         "signups": 7200,
//     }
// ];


const LineChartSales = ({data,name,updateTime}) => {

    return (
        <>            <DashboardCardHeader name={capitalizeFLetter(name)} updateTime={updateTime}/>
        <ResponsiveContainer height={250}>
            <LineChart width={480} height={250} data={data}
                       margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                <defs>
                    <filter id="shadow" height="200%">
                        <feDropShadow
                            dx="0" dy="5" stdDeviation="8"
                            floodColor={"#82ca9d"}
                        />
                    </filter>
                </defs>
                <CartesianGrid strokeDasharray="6 1 2" horizontal={false} strokeOpacity={0.3}/>
                <XAxis dataKey="month" axisLine={false} tickLine={false}/>
                <YAxis dataKey={"count"} axisLine={false} tickLine={false}/>
                <Tooltip
                    content={({active, label, payload}) => {
                        return active ? (
                            <Div sx={{color: "common.white"}}>
                                {payload.map((row, index) => {
                                    return (
                                        <div key={index} className={index !== payload.length - 1 ? "mb-1" : ""}>
                                            <div style={{
                                                color: row.color,
                                                fontSize: 8,
                                                letterSpacing: 2,
                                                textTransform: 'uppercase'
                                            }}>
                                                {capitalizeFLetter(row.name)}
                                            </div>
                                            <div style={{
                                                color: row.color
                                            }}
                                            >{row.value} {name}
                                            </div>
                                        </div>
                                    )
                                })}
                            </Div>
                        ) : null;
                    }}
                    wrapperStyle={{
                        background: 'rgba(0,0,0,0.9)',
                        borderRadius: 4,
                        padding: '5px 8px',
                        fontWeight: 500,
                        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'
                    }}
                />
                <Line type="monotone" strokeWidth={2} dataKey="count" stroke="#82ca9d" filter="url(#shadow)"/>
            </LineChart>
        </ResponsiveContainer>
        </>
    );
};

export default LineChartSales;
