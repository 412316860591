import React, {useEffect, useState} from "react";
import {CircularProgress, Grid, Typography} from "@mui/material";
import SalesStatistics from "../shared/metrics/SalesStatistics";
import AppUsers from "../shared/metrics/AppUsers";
import axios from "axios";
import toast from "react-hot-toast";

const Dashboard = () => {
    const [data, setData] = useState()
    const [updateTime, setUpdateTime] = useState();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const getData = async () => {
            try {
                setLoading(true)
                const response = await axios.get('/dashboard-data/v1', {});
                if (response.status === 200) {
                    // console.log(response.data);
                    setData(response.data);
                    setUpdateTime(Date.now())
                }
            } catch (err) {
                setLoading(false);
                toast.error('Unable to fetch Data')
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, []);
    return (
        <>
            {
                loading ?
                    (<>
                        <CircularProgress/>
                        <Typography variant="h6" marginTop={2}></Typography>
                    </>)
                    :
                    (<Grid container spacing={3.75}>
                        <Grid item xs={12}>
                            <SalesStatistics regByMonth={data?.regByMonth} ridesByMonth={data?.ridesByMonth}
                                             updateTime={updateTime}/>
                        </Grid>
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <SalesReport/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <YearlyProfileReport/>*/}
                        {/*</Grid>*/}
                        <Grid item xs={12} sm={6} lg={3}>
                            <AppUsers totalUsers={data?.iosUsers} androidUsers={data?.androidUsers}
                                      updateTime={updateTime}/>
                        </Grid>
                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <OnlineVisitors/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <OnlineVisitors/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <OrdersReport subTitle={null} chartHeight={183}/>*/}
                        {/*</Grid>*/}

                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <MarketingCampaign scrollHeight={428}/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} md={6}>*/}
                        {/*    <LatestAlerts scrollHeight={385}/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12}>*/}
                        {/*     <SiteVisitors />*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <LastMonthSales/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <OnlineSignupsFilled/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <NewVisitorsThisMonth/>*/}
                        {/*</Grid>*/}
                        {/*<Grid item xs={12} sm={6} lg={3}>*/}
                        {/*    <TotalRevenueThisYear/>*/}
                        {/*</Grid>*/}
                    </Grid>)
            }
        </>

    );
}
export default Dashboard;
