import React, { useEffect } from "react";

import { Grid, Typography } from "@mui/material";

import axios from "axios";
import Datagrid from "app/components/Datagrid";
import { useTranslation } from "react-i18next";

const Riders = () => {
  const [riders, setRiders] = React.useState([]);

  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phone", headerName: "Phone", width: 150 },
    { field: "gender", headerName: "Gender", width: 150 },
    {
      headerName: "Registered At",
      field: "_id",
      sortable: false,
      width: 175,
      renderCell: (params) => (
        <Typography variant="body">
          {new Date(params.row.createdAt).toLocaleString()}
        </Typography>
      ),
    },
    // {
    //   headerName: "Details",
    //   field: "id",
    //   sortable: false,
    //   width: 125,
    //   renderCell: (params) => (
    //     <Button
    //       variant="outlined"
    //       color="primary"
    //       onClick={() => {
    //         // alert(params.row.id);
    //         setModalData(params.row);
    //         handleOpen(params.row._id);
    //       }}
    //     >
    //       Details
    //     </Button>
    //   ),
    // },
  ];

  useEffect(() => {
    // toast.loading("fetching data", { id: "data" });

    axios
      .get("/riders")
      .then(({ data }) => {
        // console.log(data);
        // toast.success("Data Loaded", { id: "data" });
        setRiders(data.riders);
      })
      .catch(() => {
        // toast.error("Error Fetching Data", { id: "data" });
      });
  }, []);
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h1" mb={3}>
        {t("pages.title.riders")}
      </Typography>

      {/* Modal  */}

      <Datagrid url={'/riders'} columns={columns} />
    </>
  );
};

export default Riders;
