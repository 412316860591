import React, {useState} from "react";
import Datagrid from "../components/Datagrid";
import {Avatar, Box, Button, Grid, ListItem, Modal, TextField, Typography,} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";
import axios from "axios";
import toast from "react-hot-toast";
import {fileURL} from "../context/constants";
import Backdrop from "@mui/material/Backdrop";
import {useDropzone} from "react-dropzone";
import List from "@mui/material/List";
import DndWrapper from "app/layouts/shared/DndWrapper";
import {useTranslation} from "react-i18next";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    height: "80vh",
    width: "40%",
    overflowY: "auto",
};
const SubAdmins = () => {
    const [open, setOpen] = React.useState(false);
    const [adding, setAdding] = useState(false);
    const [id, setId] = useState();
    const [email, setEmail] = useState({
        value: "",
        error: false,
        helperText: "",
    });

    const [password, setPassword] = useState({
        value: "",
        error: false,
        helperText: "",
    });

    const [firstName, setFirstName] = useState({
        value: "",
        error: false,
        helperText: "",
    });
    const [lastname, setLastname] = useState({
        value: "",
        error: false,
        helperText: "",
    });
    const [mobile, setMobile] = useState({
        value: "",
        error: false,
        helperText: "",
    });
    const handleClose = () => {
        setOpen(false);
    };
    const columns = [
        {
            field: "image",
            width: 150,
            renderCell: (params) => (
                <Avatar alt="Cindy Baker" src={fileURL + params.row.image}/>
            ),
        },
        {field: "lastname", headerName: "Last Name", width: 150},
        {field: "email", headerName: "Email", width: 150},
        {field: "phone", headerName: "Phone", width: 150},
        {
            headerName: "Registered At",
            field: "createdAt",
            sortable: false,
            width: 175,
            renderCell: (params) => (
                <Typography variant="body">
                    {new Date(params.row.createdAt).toLocaleString()}
                </Typography>
            ),
        },
        {
            headerName: "Update",
            field: "id",
            sortable: false,
            width: 125,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        handleOpen(params.row);
                    }}
                >
                    Update
                </Button>
            ),
        },
        {
            headerName: "Delete",
            field: "_id",
            sortable: false,
            width: 125,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        // alert(params.row.id);
                        handleDelete(params.row._id);
                    }}
                >
                    Delete
                </Button>
            ),
        },
    ];


    const openAddModal = (data, isUpdate) => {
        setFirstName({
            value: "",
            error: false,
            helperText: "",
        });
        setLastname({
            value: "",
            error: false,
            helperText: "",
        });
        setEmail({
            value: "",
            error: false,
            helperText: "",
        });
        setMobile({
            value: "",
            error: false,
            helperText: "",
        });
        setPassword({
            value: "",
            error: false,
            helperText: "",
        });

        setOpen(true);
        setAdding(true);
    };
    const handleOpen = (data, isUpdate) => {
        setOpen(true);
        // console.log("data--->", data);
        setFirstName({
            value: data.name,
            error: false,
            helperText: "",
        });
        setLastname({
            value: data.lastname,
            error: false,
            helperText: "",
        });
        setEmail({
            value: data.email,
            error: false,
            helperText: "",
        });
        setMobile({
            value: data.phone,
            error: false,
            helperText: "",
        });
        setId(data._id);
    };
    const [refetch, setRefetch] = useState(1);


    const handleSubmit = (event) => {
        // validation
        if (!firstName.value) {
            let tmp = {...firstName};
            tmp.error = true;
            tmp.helperText = "Provide a valid name";
            return setFirstName(tmp);
        }
        if (!lastname.value) {
            let tmp = {...lastname};
            tmp.error = true;
            tmp.helperText = "Provide a valid name";
            return setLastname(tmp);
        }
        if (!mobile.value) {
            let tmp = {...mobile};
            tmp.error = true;
            tmp.helperText = "Provide a valid name";
            return setMobile(tmp);
        }
        // if (!password.value) {
        //   let tmp = { ...password };
        //   tmp.error = true;
        //   tmp.helperText = "Provide a valid password";
        //   return setPassword(tmp);
        // }
        // if (!file) {
        //   let tmp = { ...firstName };
        //   tmp.error = true;
        //   tmp.helperText = "Provide a valid name";
        //   return setFirstName(tmp);
        // }
        // console.log(id);
        let formData = new FormData();
        formData.append("image", acceptedFiles[0]);
        formData.append("email", email.value);
        formData.append("name", firstName.value);
        formData.append("lastname", lastname.value);
        formData.append("phone", mobile.value);
        formData.append("password", password.value);

        if (adding) {
            toast.loading("Adding SubAdmin", {id: "saA"});
            axios
                .post("/addSubAdmin", formData)
                .then(({data}) => {
                    toast.success("SubAdmin Added", {id: "saA"});

                    setRefetch(refetch + 1)

                    handleClose();
                })
                .catch(() => {
                    toast.error("Unable to add SubAdmin", {id: "saA"});
                });
        } else {
            formData.append("id", id);

            toast.loading("Updating SubAdmin", {id: "sa"});
            axios
                .post("/updateSubAdmin", formData)
                .then(({data}) => {
                    toast.success("SubAdmin Updated", {id: "sa"});

                    // console.log(data);
                    // setDrivers(data.subAdmins);
                    setRefetch(refetch + 1)
                    handleClose();
                })
                .catch(() => {
                    toast.error("Unable to update SubAdmin", {id: "sa"});
                });
        }
    };

    const handleDelete = (id) => {
        toast.loading("Deleting SubAdmin", {id: "DSA"});
        axios
            .get("/deleteSubAdmin/".concat(id))
            .then(({data}) => {
                toast.success("SubAdmin Deleted", {id: "DSA"});

                setRefetch(refetch + 1)
            })
            .catch(() => {
                toast.error("Unable to delete SubAdmin", {id: "DSA"});
            });
    };
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
        maxFiles: 1,
    });
    const files = acceptedFiles.map((file) => (
        <ListItem selected key={file.path} sx={{width: "auto", mr: 1}}>
            {file.path} - {file.size} bytes
        </ListItem>
    ));
    const {t} = useTranslation();
    return (
        <>
            <Grid container justifyContent="space-between">
                <Typography variant="h1" mb={3}>
                    {t("pages.title.subAdmins")}
                </Typography>
                <Grid item></Grid>
                <Grid item>
                    <Button variant="contained" onClick={openAddModal}>
                        Add
                    </Button>
                </Grid>
            </Grid>
            {/* Modal  */}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                {/* Modal Content */}
                <Box sx={style}>
                    {/* <FormControl onSubmit={handleSubmit}> */}
                    <Grid
                        container
                        direction={"row"}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h1" mb={3}>
                            {t("pages.title.drivers")}
                        </Typography>
                        <Grid>
                            <Button sx={{color: "black"}} onClick={handleClose}>
                                <CloseOutlined/>
                            </Button>
                        </Grid>
                    </Grid>

                    {/*  Text Grids */}
                    <Grid
                        container
                        width={"100%"}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <Typography variant="body1" fontWeight="semi-bold" marginY="20px">
                                First Name
                            </Typography>
                        </Grid>
                        <Grid>
                            <TextField
                                size="small"
                                id="outlined-basic"
                                value={firstName.value}
                                onChange={(e) =>
                                    setFirstName({
                                        ...firstName,
                                        value: e.target.value,
                                        error: false,
                                    })
                                }
                                helperText={firstName.helperText}
                                error={firstName.error}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        width={"100%"}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <Typography variant="body2" fontWeight="bold" marginY="20px">
                                Last Name
                            </Typography>
                        </Grid>
                        <Grid>
                            <TextField
                                size="small"
                                id="outlined-basic"
                                variant="outlined"
                                value={lastname.value}
                                onChange={(e) =>
                                    setLastname({
                                        ...lastname,
                                        value: e.target.value,
                                        error: false,
                                    })
                                }
                                helperText={lastname.helperText}
                                error={lastname.error}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        width={"100%"}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <Typography variant="body2" fontWeight="bold" marginY="20px">
                                Email
                            </Typography>
                        </Grid>
                        <Grid>
                            <TextField
                                size="small"
                                id="outlined-basic"
                                value={email.value}
                                onChange={(e) =>
                                    setEmail({
                                        ...email,
                                        value: e.target.value,
                                        error: !emailRegex.test(e.target.value),
                                    })
                                }
                                helperText={email.helperText}
                                error={email.error}
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        width={"100%"}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <Typography variant="body2" fontWeight="bold" marginY="20px">
                                Mobile
                            </Typography>
                        </Grid>
                        <Grid>
                            <TextField
                                size="small"
                                id="outlined-basic"
                                variant="outlined"
                                value={mobile.value}
                                type="number"
                                onChange={(e) =>
                                    setMobile({
                                        ...mobile,
                                        value: e.target.value,
                                        error: false,
                                    })
                                }
                                helperText={mobile.helperText}
                                error={mobile.error}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        width={"100%"}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Grid item xs={4}>
                            <Typography variant="body2" fontWeight="bold" marginY="20px">
                                Password
                            </Typography>
                        </Grid>
                        <Grid item>
                            <TextField
                                size="small"
                                id="outlined-basic"
                                variant="outlined"
                                type="password"
                                value={password.value}
                                onChange={(e) =>
                                    setPassword({
                                        ...password,
                                        value: e.target.value,
                                        error: false,
                                    })
                                }
                                helperText={password.helperText}
                                error={password.error}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        width={"100%"}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {/* <Grid item xs={4}>
              <Typography variant="body2" fontWeight="bold" marginY="20px">
                Image
              </Typography>
            </Grid> */}
                        <Grid item sx={2}>
                            <DndWrapper>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Typography variant={"body1"}>
                                        Drag 'n' drop image here, or click to select
                                    </Typography>
                                </div>
                            </DndWrapper>
                            <Typography variant={"h4"}>Files</Typography>
                            <List
                                disablePadding
                                sx={{display: "flex", flexDirection: "column"}}
                            >
                                {files}
                            </List>
                        </Grid>
                    </Grid>
                    <Grid container columnGap={5} marginBottom="50px">
                        <Typography color="error">{}</Typography>
                    </Grid>
                    {/* Add Button */}
                    <Grid container justifyContent="center">
                        <Button
                            variant="contained"
                            sx={{
                                width: "300px",
                            }}
                            type="submit"
                            onClick={handleSubmit}
                        >
                            Add
                        </Button>
                    </Grid>
                    {/* </Box> */}
                    {/* </FormControl> */}
                </Box>
            </Modal>

            <Datagrid url={'/subadmins'} columns={columns} refetch={refetch}/>
        </>
    );
};

export default SubAdmins;
