import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Card, Grid } from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";

const Datagrid = ({ columns, url, refetch }) => {
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const fetchData = async (page, pageSize) => {
        setLoading(true);
        try {
            const response = await axios.get(`${url}?page=${page + 1}&pageSize=${pageSize}`);
            const data = response.data;
            setRows(data.rows);
            setRowCount(data.totalRows);
            toast.success("Data Loaded!");
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(page, pageSize);
    }, [page, pageSize, url, refetch]);

    return (
        <Grid container>
            <Card style={{ width: "100%" }}>
                <DataGrid
                    rows={rows}
                    getRowId={(r) => r._id}
                    columns={columns}
                    page={page}
                    pageSize={pageSize}
                    rowCount={rowCount}
                    paginationMode="server"
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    loading={loading}
                    disableColumnFilter
                    disableColumnSelector
                    disableAutosize
                    disableColumnResize={true}
                    disableDensitySelector
                    slots={{ toolbar: GridToolbar }}
                    disableSelectionOnClick={true}
                    showQuickFilter={false}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                        },
                    }}
                />
            </Card>
        </Grid>
    );
};

export default Datagrid;
