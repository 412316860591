import React, { useEffect, useState } from "react";
import Datagrid from "../components/Datagrid";
import { Button, Grid, Typography, Modal, Box, TextField } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import axios from "axios";
import toast from "react-hot-toast";
import { fileURL } from "../context/constants";
import { useTranslation } from "react-i18next";
import {MuiFileInput} from "mui-file-input";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  height: "70vh",
  width: "60vw",
  overflowY: "auto",
};

const IntroScreens = () => {
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);
  const [fileError, setFileError] = useState(false);
  const [screens, setScreens] = React.useState([]);
  const [screenDetails, setScreenDetails] = React.useState();
  const [adding, setAdding] = useState(false);
  const [id, setId] = useState();
  const [order, setOrder] = useState({
    value: "",
    error: false,
    helperText: "",
  });
  const [heading, setHeading] = useState({
    value: "",
    error: false,
    helperText: "",
  });
  const [text, setText] = useState({
    value: "",
    error: false,
    helperText: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      headerName: "Order",
      field: "order",
      sortable: true,
      width: 100,
    },
    { field: "heading", headerName: "Heading", width: 150 },
    { field: "text", headerName: "Text", width: 225 },
    {
      headerName: "Image",
      field: "image",
      sortable: true,
      width: 125,
      renderCell: (params) => (
          <a href={`${fileURL}${params.row.image}`} target="_blank" rel="noopener noreferrer">
            <Button variant="outlined" color="success">
              Image
            </Button>
          </a>
      ),
    },
    {
      headerName: "Update",
      field: "id",
      sortable: false,
      width: 125,
      renderCell: (params) => (
          <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                handleOpen(params.row);
              }}
          >
            Update
          </Button>
      ),
    },
    {
      headerName: "Delete",
      field: "_id",
      sortable: false,
      width: 125,
      renderCell: (params) => (
          <Button
              variant="outlined"
              color="error"
              onClick={() => {
                handleDelete(params.row._id);
              }}
          >
            Delete
          </Button>
      ),
    },
  ];

  const handleFileChange = (newFile) => {
    setFileError(false);
    setFile(newFile);
  };

  const openAddModal = (data, isUpdate) => {
    setOrder({
      value: "",
      error: false,
      helperText: "",
    });
    setHeading({
      value: "",
      error: false,
      helperText: "",
    });
    setText({
      value: "",
      error: false,
      helperText: "",
    });

    setFile(null);

    setOpen(true);
    setAdding(true);
  };

  const handleOpen = (data, isUpdate) => {
    setOrder({
      value: data.order,
      error: false,
      helperText: "",
    });
    setText({
      value: data.text,
      error: false,
      helperText: "",
    });
    setHeading({
      value: data.heading,
      error: false,
      helperText: "",
    });
    setAdding(false);
    setId(data._id);
    setOpen(true);
  };

  // const getData = () => {
  //   toast.loading("Fetching data", { id: "data" });
  //
  //   axios
  //       .get("/splashscreens")
  //       .then(({ data }) => {
  //         setScreens(data.screens);
  //         toast.success("Data Updated", { id: "data" });
  //       })
  //       .catch(() => {
  //         toast.error("Error Fetching Data", { id: "data" });
  //       });
  // };
  //
  // useEffect(() => {
  //   getData();
  // }, []);

  const handleSubmit = (event) => {
    // validation
    if (!order.value) {
      setOrder({
        ...order,
        error: true,
        helperText: "Provide a valid order",
      });
      return;
    }
    if (!heading.value) {
      setHeading({
        ...heading,
        error: true,
        helperText: "Provide a heading",
      });
      return;
    }
    if (!text.value) {
      setText({
        ...text,
        error: true,
        helperText: "Provide a text",
      });
      return;
    }

    let formData = new FormData();
    formData.append("image", file);
    formData.append("order", order.value);
    formData.append("heading", heading.value);
    formData.append("text", text.value);

    if (adding) {
      if (!file) {
        setFileError(true);
        return;
      }
      toast.loading("Adding Intro Screen", { id: "addScreen" });
      axios
          .post("/addSplashScreen", formData)
          .then(({ data }) => {
            toast.success("Intro Screen Added", { id: "addScreen" });
            // getData();
            handleClose();
          })
          .catch(() => {
            toast.error("Unable to add Intro Screen", { id: "addScreen" });
          });
    } else {
      formData.append("id", id);
      toast.loading("Updating Intro Screen", { id: "updateScreen" });
      axios
          .post("/updateSplashScreen", formData)
          .then(({ data }) => {
            toast.success("Intro Screen Updated", { id: "updateScreen" });
            // getData();
            handleClose();
          })
          .catch(() => {
            toast.error("Unable to update Intro Screen", { id: "updateScreen" });
          });
    }
  };

  const handleDelete = (id) => {
    toast.loading("Deleting Intro Screen", { id: "deleteScreen" });
    axios
        .get("/deleteSplashScreen/".concat(id))
        .then(({ data }) => {
          toast.success("Intro Screen Deleted", { id: "deleteScreen" });
          // getData();
        })
        .catch(() => {
          toast.error("Unable to delete Intro Screen", { id: "deleteScreen" });
        });
  };

  const { t } = useTranslation();

  return (
      <>
        <Typography variant="h1" mb={3}>
          {t("pages.title.IntroScreens")}
        </Typography>
        <Grid container justifyContent="space-between">
          <Grid item></Grid>
          <Grid item>
            <Button variant="contained" onClick={openAddModal} sx={{marginBottom:'8px'}}>
              {t("pages.actions.add")}
            </Button>
          </Grid>
        </Grid>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid container direction={"row"} justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h5" fontWeight="bold" marginBottom={4}>
                  {adding ? t("pages.actions.add") : t("pages.actions.update")} {t("pages.title.IntroScreen")}
                </Typography>
              </Grid>
              <Grid>
                <Button sx={{ color: "black" }} onClick={handleClose}>
                  <CloseOutlined />
                </Button>
              </Grid>
            </Grid>

            <Grid container width={"100%"} justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <Typography variant="body2" fontWeight="bold" marginY="20px">
                  {t("pages.fields.order")}
                </Typography>
              </Grid>
              <Grid>
                <TextField
                    size="small"
                    id="outlined-basic"
                    value={order.value}
                    type="number"
                    onChange={(e) =>
                        setOrder({
                          ...order,
                          value: e.target.value,
                          error: false,
                        })
                    }
                    helperText={order.helperText}
                    error={order.error}
                    variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container width={"100%"} justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <Typography variant="body2" fontWeight="bold" marginY="20px">
                  {t("pages.fields.heading")}
                </Typography>
              </Grid>
              <Grid>
                <TextField
                    size="small"
                    id="outlined-basic"
                    variant="outlined"
                    value={heading.value}
                    onChange={(e) =>
                        setHeading({
                          ...heading,
                          value: e.target.value,
                          error: false,
                        })
                    }
                    helperText={heading.helperText}
                    error={heading.error}
                />
              </Grid>
            </Grid>

            <Grid container width={"100%"} justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <Typography variant="body2" fontWeight="bold" marginY="20px">
                  {t("pages.fields.text")}
                </Typography>
              </Grid>
              <Grid>
                <TextField
                    size="small"
                    id="outlined-basic"
                    value={text.value}
                    onChange={(e) =>
                        setText({
                          ...text,
                          value: e.target.value,
                          error: false,
                        })
                    }
                    helperText={text.helperText}
                    error={text.error}
                    variant="outlined"
                />
              </Grid>
            </Grid>

            <Grid container width={"100%"} justifyContent="center" alignItems="center">
              <Grid item xs={4}>
                <Typography variant="body2" fontWeight="bold" marginY="20px">
                  {t("pages.fields.image")}
                </Typography>
              </Grid>
              <Grid item sx={2}>
                <MuiFileInput
                    value={file}
                    onChange={handleFileChange}
                    variant="outlined"
                    size="small"
                    sx={{ width: 225 }}
                    helperText={t("pages.helperText.uploadImage")}
                    error={fileError}
                />
              </Grid>
            </Grid>

            <Grid container columnGap={5} marginBottom="50px">
              <Typography color="error">{}</Typography>
            </Grid>

            <Grid container justifyContent="center">
              <Button
                  variant="contained"
                  disabled={screenDetails?.isVerified}
                  sx={{
                    width: "300px",
                  }}
                  type="submit"
                  onClick={handleSubmit}
              >
                {adding ? t("pages.actions.add") : t("pages.actions.update")}
              </Button>
            </Grid>
          </Box>
        </Modal>

        <Datagrid url="/splashscreens" columns={columns} />
      </>
  );
};

export default IntroScreens;

