import React, {useState} from "react";
import {Box, Button, Divider, Grid, Modal, Stack, Typography} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";
import axios from "axios";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import Datagrid from "app/components/Datagrid";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    height: "70vh",
    width:'500px',
    overflowY: "auto",
};

const Drivers = () => {
    const [open, setOpen] = useState(false);
    const [carData, setCarData] = useState({});
    const [driverDetails, setDriverDetails] = useState(null);
    const [modalData, setModalData] = useState(null);
    const {t} = useTranslation();
    const [refetch, setRefetch] = useState(1);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = async (id) => {
        await axios.get(`/car-data/${id}`)
            .then(({data}) => {
                setCarData(data.car);
                setDriverDetails(data.driverDetails);
                setOpen(true);
            })
            .catch(() => {
                toast.error("Unable to load Data", {id: "drivers"});
            });
    };

    const verifyDriver = async (id) => {
        toast.loading("Verifying", {id: "drivers"});
        axios.get(`/verifyDriver/${id}`)
            .then(() => {
                toast.success("Verified Successfully", {id: "drivers"});
                handleClose();
                // fetchDrivers();
                setRefetch(refetch + 1)

            })
            .catch(() => {
                toast.error("Unable to Verify", {id: "drivers"});
            });
    };

    const columns = [
        {
            headerName: "Name",
            width: 250,
            renderCell: (params) => (
                <Typography variant="body">{params.row?.driverId?.name}</Typography>
            ),
        },
        {
            field: "email",
            renderCell: (params) => (
                <Typography variant="body">{params.row?.driverId?.email}</Typography>
            ),
            headerName: "Email",
            width: 250,
        },
        {
            field: "phone",
            renderCell: (params) => (
                <Typography variant="body">{params.row?.driverId?.phone}</Typography>
            ),
            headerName: "Phone",
            width: 200,
        },
        {
            field: "isVerified",
            renderCell: (params) => (
                <Typography variant="body" color={!params.row?.isVerified && "error"}>
                    {params.row?.isVerified ? "Verified" : "Pending"}
                </Typography>
            ),
            headerName: "Verified",
            width: 150,
        },
        {
            field: "Verified",
            renderCell: (params) => (
                <Typography variant="body">{params.row?._id}</Typography>
            ),
            headerName: "Status",
            width: 150,
        },
        {
            headerName: "Registered At",
            field: "_id",
            sortable: false,
            width: 175,
            renderCell: (params) => (
                <Typography variant="body">
                    {new Date(params.row.createdAt).toLocaleString()}
                </Typography>
            ),
        },
        {
            headerName: "Details",
            field: "id",
            sortable: false,
            width: 125,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setModalData(params.row.driverId);
                        console.log('modalData', modalData)
                        handleOpen(params.row.driverId._id);
                    }}
                >
                    Details
                </Button>
            ),
        },
    ];

    return (
        <>
            <Typography variant="h1" mb={3}>
                {t("pages.title.drivers")}
            </Typography>

            <Grid container justifyContent="space-between">
                <Grid item></Grid>
                <Grid item></Grid>
            </Grid>

            {modalData && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid container justifyContent="space-between">
                            <Grid>
                                <Typography variant="h5" fontWeight="bold" marginBottom={4}>
                                    Driver Details
                                </Typography>
                            </Grid>
                            <Grid>
                                <Button sx={{color: "black"}} onClick={handleClose}>
                                    <CloseOutlined/>
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">Email</Typography>
                                <Typography fontSize="16px">{modalData.email}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">Phone</Typography>
                                <Typography fontSize="16px">{modalData.phone}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">Name</Typography>
                                <Typography fontSize="16px">{modalData.name}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">City</Typography>
                                <Typography fontSize="16px">{modalData.city}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">Address</Typography>
                                <Typography fontSize="16px">{modalData.address}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">State</Typography>
                                <Typography fontSize="16px">{modalData.state}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">SSN</Typography>
                                <Typography fontSize="16px">{modalData.socialSecurityNumber}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">License Number</Typography>
                                <Typography fontSize="16px">{modalData.licenseNumber}</Typography>
                            </Stack>


                            <Divider/>
                            <h3>Car Details</h3>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">Model</Typography>
                                <Typography fontSize="16px">{carData?.carModel}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">Category</Typography>
                                <Typography fontSize="16px">{carData?.category}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">Color</Typography>
                                <Typography fontSize="16px">{carData?.color}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="bold">Registration Number</Typography>
                                <Typography fontSize="16px">{carData?.registrationNumber}</Typography>
                            </Stack>
                            {/*<Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">*/}
                            {/*    <Typography fontSize="16px" fontWeight="bold">Car Image</Typography>*/}
                            {/*</Stack>*/}
                            {/*<Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">*/}
                            {/*    <img src={fileURL.concat(carData?.carImage)} width="400px" alt="Car"/>*/}
                            {/*</Stack>*/}
                            {/*<Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">*/}
                            {/*    <Typography fontSize="16px" fontWeight="bold">Documents</Typography>*/}
                            {/*</Stack>*/}
                            {/*{modalData?.files?.map(({file}, index) => (*/}
                            {/*    <Stack key={index} direction="row" justifyContent="space-between" margin="20px 0 10px 0"*/}
                            {/*           spacing="20px">*/}
                            {/*        <img src={fileURL.concat(file)} alt="Document" width="400px"/>*/}
                            {/*    </Stack>*/}
                            {/*))}*/}
                        </Grid>
                        <Grid container columnGap={5} marginBottom="50px">
                            <Typography color="error">{}</Typography>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Button
                                variant="contained"
                                disabled={driverDetails?.isVerified}
                                sx={{width: "300px"}}
                                onClick={() => verifyDriver(driverDetails?._id)}
                            >
                                {driverDetails?.isVerified ? "Verified" : "Verify"}
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
            )}
            <Datagrid url="/drivers" columns={columns} refetch={refetch}/>
        </>
    );
};

export default Drivers;

